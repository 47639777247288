<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h4 class="text-subtitle-2" v-text="$t('common.dateAndHour')" />
      </v-col>
      <v-col cols="12">
        <v-icon
          color="primary"
          size="24"
        >
          lightbulb_outline
        </v-icon>
        <span v-text="$t('project.competition.disclaimers.date')" />
      </v-col>
      <v-col cols="12">
        <datetime-field
          v-model="form.date"
          :timezone="project.timezone"
          :error-messages="getFieldErrors('date')"
          :full-width="true"
          :initial-iso-time="form.date ? null : '00:00'"
          class="match-date-time"
          outlined
          dense
          @blur="$v.form.date.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h4 class="text-subtitle-2" v-text="$t('project.competition.headers.location')" />
      </v-col>
    </v-row>
    <v-row v-if="hasMatchLocationsFeature">
      <v-col v-if="showErrorConcurrentMatch" cols="12">
        <v-alert type="error">
          {{ $t('project.competition.errorConcurrentMatch') }}
        </v-alert>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="form.locationId" :label="$t('project.competition.labels.locationId')"
          append-icon="mdi-chevron-down" :items="locations" outlined dense
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="form.fieldId" :disabled="!form.locationId" :label="$t('project.competition.labels.fieldId')"
          append-icon="mdi-chevron-down" :items="fields" outlined dense
        />
      </v-col>
    </v-row>
    <v-row v-if="!hasMatchLocationsFeature">
      <v-col cols="12">
        <geolocation
          :location="updatedLocation"
          :custom-label="$t('project.competition.labels.address')"
          @update="data => form.location = data"
        />
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="12">
        <v-text-field
          v-model="form.comments"
          :error-messages="getFieldErrors('comments')"
          :label="$t('project.competition.labels.comments')"
          outlined
          dense
          @blur="$v.form.comments.$touch()"
        />
      </v-col>
    </v-row>
    <v-row class="my-4">
      <v-col cols="12">
        <v-btn color="primary" width="120" depressed :disabled="!isFormValid()" @click="save" v-text="$t('common.save')" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'EditDateAndLocation',
  components: {
    DatetimeField: () => import('@/components/formFields/DatetimeField'),
    Geolocation: () => import('@/components/formFields/Geolocation'),
  },
  mixins: [formUtils],
  props: {
    match: { type: Object, required: true },
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return {
      openDialog: false,
      form: {},
      showErrorConcurrentMatch: false,
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters('competition', ['matchLocations', 'matches']),
    ...mapGetters('project', ['hasMatchLocationsFeature']),
    updatedLocation: ({ form }) => form?.location?.name,
    locations: ({ matchLocations }) => matchLocations.length
      ? matchLocations.map(mL => ({ text: mL.name, value: mL.id })).sort((a, b) => a.text.localeCompare(b.text)) : [],
    fields: ({ form: { locationId }, matchLocations }) => locationId
      ? matchLocations?.find(l => l.id === locationId)?.fields?.map(f => ({ text: f.name, value: f.id })).sort((a, b) => a.text.localeCompare(b.text)) : [],
  },
  validations() {
    return {
      form: {
        date: { },
        comments: {},
      },
    }
  },
  created() {
    const { date, comments } = this.match
    this.form = {
      date: date ? new Date(date.seconds * 1000) : null,
      ...(this.hasMatchLocationsFeature && { locationId: this.match.locationId ?? null }),
      ...(this.hasMatchLocationsFeature && { fieldId: this.match.fieldId ?? null }),
      ...(!this.hasMatchLocationsFeature && {
        location: {
          name: this.match.location?.name ?? null,
          latitude: this.match.location?.latitude ?? null,
          longitude: this.match.location?.longitude ?? null,
        },
      }),
      comments: comments || null,
    }
  },
  methods: {
    save() {
      const { date, locationId, fieldId, location, comments } = this.form
      if (!this.isFormValid()) return
      this.showErrorConcurrentMatch = false
      if (this.hasMatchLocationsFeature) {
        const matchDurationInMinutes = this.matchLocations?.find(mL => mL.id === locationId)?.fields?.find(f => f.id === fieldId)?.matchDurationInMinutes
        const concurrentDate = {
          start: new Date(date.getTime() - matchDurationInMinutes * 60 * 1000),
          end: new Date(date.getTime() + matchDurationInMinutes * 60 * 1000),
        }
        const concurrentMatch = this.matches.find(match => match.locationId === locationId && match.fieldId === fieldId && `${match.cohortId}${match.id}` !== `${this.match.cohortId}${this.match.id}`
                                                  && match.date?.toDate() > concurrentDate.start && match.date?.toDate() < concurrentDate.end)

        if (concurrentMatch) {
          this.showErrorConcurrentMatch = true
          return
        }
      }
      const data = {
        date,
        ...(this.hasMatchLocationsFeature && { locationId }),
        ...(this.hasMatchLocationsFeature && { fieldId }),
        ...(!this.hasMatchLocationsFeature && { location }),
        comments,
      }

      const { organizationId, projectId } = this
      const { cohortId } = this.match
      this.runAsync(() => this.$store
        .dispatch('competition/updateMatch', { organizationId, projectId, cohortId, matchId: this.match.id, data }))
    },
  },
}
</script>

<style scoped>
.v-avatar img {
  width: 100%;
  object-fit: cover;
}
.name-team {
  width: 75%;
  color: rgba(0,0,0,.87);
}

.radio-group-type-place.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 40px;
}
</style>
